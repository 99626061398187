import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { StepsEnum } from "./constants";
import { GlobalLoader } from "../../../../utils/globalLoader";
import {
  generateAddressStart,
  getConvertedDepositBalanceStart,
} from "../../../../redux-saga/redux/payment";

export default function SelectPaymentMethod({
  setStep,
  handleCardPayment,
  loader,
  activeCard,
}) {
  const dispatch = useDispatch();
  const [currencyCode] = useState("usd");
  const {
    convertedDepositBalance,
    convertLoading,
    generateLoading,
    generateAddress,
  } = useSelector((state) => state.payment);
  const { userDetails } = useSelector((state) => state.myProfile);

  const showLoading = loader || generateLoading || convertLoading;
  useEffect(() => {
    if (convertedDepositBalance && !convertLoading) {
      dispatch(
        generateAddressStart({
          currencyCode: "usd",
          packageId: activeCard?.id,
          userName: userDetails?.user?.user_name,
          amount: activeCard?.price,
        })
      );
    }
  }, [
    activeCard,
    convertLoading,
    convertedDepositBalance,
    dispatch,
    userDetails?.user?.user_name,
  ]);

  useEffect(() => {
    if (generateAddress && convertedDepositBalance && !showLoading) {
      setStep(StepsEnum.BitCoin);
    }
  }, [convertedDepositBalance, generateAddress, setStep, showLoading]);

  const handleBitcoinPayment = () => {
    dispatch(
      getConvertedDepositBalanceStart({
        currencyCode,
        amount: activeCard.price,
      })
    );
  };

  return (
    <>
      <span>Select payment method</span>
      <div className="select-payment-methodWrap">
        <Grid className="paymentCardMainWrap">
          <div
            className="paymentCardWrap"
            onClick={handleBitcoinPayment}
            role="presentation"
          >
            <img src="/images/Bitcoin-Coin.png" alt="" />
            <p>Bitcoin</p>
          </div>
          <div
            className="paymentCardWrap"
            onClick={() => setStep(StepsEnum.CashApp)}
            role="presentation"
          >
            <img src="/images/dollar.png" alt="" className="cashappimg" />
            <p>CashApp</p>
          </div>
          <div
            className="paymentCardWrap"
            onClick={() => setStep(StepsEnum.PayMobil)}
            role="presentation"
          >
            <img src="/images/payImg.png" alt="" className="paymobileImg" />
            <p>PayMobil</p>
          </div>
          <div
            className="paymentCardWrap"
            onClick={handleCardPayment}
            role="presentation"
          >
            <img src="/images/carNew.png" alt="" className="visaCardImg" />
            <p>Debit/credit card</p>
          </div>
        </Grid>
        <div className="select-payment-method-button select-btn-mobile">
          <button disabled={loader} onClick={() => setStep(StepsEnum.Amount)}>
            {showLoading ? <GlobalLoader /> : "Back"}
          </button>
        </div>
      </div>
    </>
  );
}
