import React from "react";

import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import useStyles from "../../container/Casino/Lobby/Lobby.styles";

const TransactionPage = ({
  data,
  currentPage,
  pageLimit,
  handleCanceTransaction,
  handleViewComments,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state?.wallet?.walletData?.[0]);
  console.log("[Store]", store);

  return (
    <>
      <Grid className={`${classes.tableResponsive} transaction-scroller`}>
        <div className="mobileTableMainWrap">
          <div className="theadMainWrap">
            <div className="transaction-head">
              <div className="transaction-head-column">
                <span>ID</span>
              </div>
              <div className="transaction-head-column">
                <span>Date/Time</span>
              </div>
              <div className="transaction-head-column">
                <span>Payment type</span>
              </div>
              <div className="transaction-head-column">
                <span>Payment method</span>
              </div>
              <div className="transaction-head-column">
                <span>Amount</span>
              </div>
              <div className="transaction-head-column">
                <span>Status</span>
              </div>
              <div className="transaction-head-column">
                <span>Before balance</span>
              </div>
              <div className="transaction-head-column">
                <span>After balance</span>
              </div>
              <div className="transaction-head-column">
                <span>Action</span>
              </div>
              <div className="transaction-head-column">
                <span>Comment</span>
              </div>
            </div>

            {data && data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <>
                    <div className="transaction-body">
                      <div className="transaction-body-row">
                        <div className="transaction-body-column">
                          <span>{currentPage * pageLimit + index + 1}</span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            {item?.created_at &&
                              `${new Date(item.created_at).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}`}
                          </span>
                          <span>{`${new Date(
                            item.created_at
                          ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })}`}</span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            {(item?.transaction &&
                              item?.transaction[0]?.toUpperCase() +
                                item?.transaction.slice(1)) ||
                              (item?.transaction_type === "3"
                                ? "Deposit"
                                : "Withdraw")}
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            <img
                              src={
                                item.payment === "XCoin"
                                  ? "/images/card12.png"
                                  : item.payment === "CashApp"
                                  ? "/images/new-cashapp-icon.svg"
                                  : "/images/new-bitcoin-icon.svg"
                              }
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span>
                              {item?.payment === "XCoin"
                                ? "Debit/Credit Card"
                                : item?.payment}
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            <img
                              src="/images/Gold-coin1.png"
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span style={{ color: "#fff" }}>
                              {Number(item?.amount || 0).toFixed(2)}
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span
                            style={{
                              // paddingLeft: "18px",
                              color:
                                item.status === "cancelled" ||
                                item.status === "rejected"
                                  ? "#F25C5C"
                                  : item.status === "pending"
                                  ? "#FFC329"
                                  : "#6CD9A5",
                            }}
                          >
                            {item.status === "success"
                              ? "Confirmed"
                              : item?.status[0]?.toUpperCase() +
                                item?.status?.slice(1)}
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            <img
                              src="/images/Gold-coin1.png"
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span style={{ color: "#fff" }}>
                              {item?.payment === "CashApp" &&
                              (item?.transaction_type?.toLowerCase() ===
                                "credit" ||
                                item?.transaction_type?.toLowerCase() ===
                                  "deposit" ||
                                item?.transaction?.toLowerCase() ===
                                  "deposit" ||
                                item?.transaction?.toLowerCase() === "withdraw")
                                ? item.status === "pending"
                                  ? Number(store?.amount).toFixed(2)
                                  : item.status === "confirmed" &&
                                    item.wallet_balance
                                  ? Number(item.wallet_balance.goldCoins) -
                                    Number(item.gold_coins)
                                  : Number(item?.current_balance || 0).toFixed(
                                      2
                                    )
                                : Number(item?.target_before_balance).toFixed(
                                    2
                                  )}
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            <img
                              src="/images/Gold-coin1.png"
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span style={{ color: "#fff" }}>
                              {item?.payment === "CashApp" &&
                              (item?.transaction_type?.toLowerCase() ===
                                "credit" ||
                                item?.transaction_type?.toLowerCase() ===
                                  "deposit" ||
                                item?.transaction?.toLowerCase() === "deposit")
                                ? item.status === "pending"
                                  ? (
                                      Number(store?.amount || 0) +
                                      Number(item?.gold_coins || 0)
                                    ).toFixed(2)
                                  : item.status === "confirmed" &&
                                    item.wallet_balance
                                  ? Number(item.wallet_balance.goldCoins)
                                  : (
                                      Number(item?.current_balance || 0) +
                                      Number(item?.amount || 0)
                                    )?.toFixed(2)
                                : item?.payment === "CashApp" &&
                                  (item?.transaction_type?.toLowerCase() ===
                                    "debit" ||
                                    item?.transaction_type?.toLowerCase() ===
                                      "withdraw" ||
                                    item?.transaction?.toLowerCase() ===
                                      "withdraw")
                                ? (
                                    Number(item?.current_balance || 0) -
                                    Number(item?.amount || 0)
                                  )?.toFixed(2)
                                : Number(
                                    item?.target_after_balance || 0
                                  ).toFixed(2)}
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          {item.status !== "pending" &&
                          (item?.transaction !== "withdraw" ||
                            item?.transaction_type !== "4") ? (
                            <span>Not available</span>
                          ) : item.status === "pending" &&
                            (item?.transaction === "withdraw" ||
                              item?.transaction_type === "4") ? (
                            <span
                              className="btn_Cancel"
                              onClick={() => handleCanceTransaction(item)}
                              role="presentation"
                            >
                              Cancel
                            </span>
                          ) : (
                            <span>Not available</span>
                          )}
                        </div>
                        <div className="transaction-body-column">
                          {item.status === "pending" ||
                          item.status === "cancelled" ? (
                            <span>Not available</span>
                          ) : (
                            <span
                              className="btn_Cancel btn_views"
                              onClick={() => handleViewComments(item?.comments)}
                              role="presentation"
                            >
                              View
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "#757786",
                  fontSize: "14px",
                  marginBottom: "10px",
                }}
              >
                <span style={{ textAlign: "center" }}>No History Found</span>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default TransactionPage;
