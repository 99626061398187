/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useTransition } from "react";

import { Grid, Typography, Button, Tab, Tabs, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import PropTypes from "prop-types";
// import QRCode from "react-qr-code"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

// import ReactSelect from "react-select"
// import coinIcon from "../../../../public/images/Gold-coin.png"
// import bonusIcon from "../../../../public/images/bonus.svg"
import useStyles from "../Deposit.styles";
import {
  // generateAddressStart,
  getCurrencyListStart,
  clearDepositData,
  // getConvertedDepositBalanceStart,
  clearConvetedDepositCurrency,
  clearConvetedWithdrawCurrency,
} from "../../../../redux-saga/redux/payment";
import { Withdraw } from "../withdraw";
import {
  clearDepositStates,
  getCashAppTransactionStart,
  getDefaultPayDataStart,
} from "../../../../redux-saga/redux/CashApp";
// import CashAppForm from "./cashAppForm"
// import BillingAddressForm from "./billingAddress"
import paymentService from "../../../../services/payment";
import config from "../../../../config/index";
import SelectedStateView from "./selectedStateView";
import storeService from "../../../../services/store";
import { HeaderEnums, ShowTabsEnum } from "./constants";

// let timeoutValue;

const StepsEnum = Object.freeze({
  Amount: "amount",
  Method: "method",
  BitCoin: "bitcoin",
  CashApp: "cashapp",
  PayMobil: "paymobil",
  Card: "card",
  Success: "success",
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BuyCoins = ({ handleDepositWithdrawOpen }) => {
  if (!localStorage.theme) localStorage.setItem("theme", "dark");
  const [isPending, startTransition] = useTransition();
  //   const { darkMode } = useSelector((state) => state.themeSettings)
  const { billingUpdateSuccess } = useSelector((state) => state.authentication);
  const classes = useStyles();
  const [step, setStep] = useState(StepsEnum.Amount);
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  // const [selectedCurrency, setSelectedCurrency] = useState("");
  const [showStatus] = useState("normal");
  // const [amount] = useState("");
  // const [webReceipt, setwebReceipt] = useState("");
  //   const [formState, setFormState] = useState("idle")
  const [showHeader, setShowHeader] = useState(true);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  //const [openBillingForm, setOpenBillingForm] = useState(false);
  const { cashAppPayList } = useSelector((state) => state.CashApp);
  const [activeCard, setActiveCard] = useState(null);
  const [imageChange] = useState();
  const {
    currencyList,
    // generateAddress,
    // generateLoading,
    // convertedDepositBalance,
    // convertLoading,
    withdrawStatus,
  } = useSelector((state) => state.payment);
  const [coinPackages, setCoinPackages] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const getCoinPackages = async () => {
      const data = await storeService.getCoinPackages({ setLoader });
      startTransition(() => {
        setCoinPackages(data);
      });
    };
    getCoinPackages();
  }, []);

  // const handleAmount = (e) => {
  //   if (!e?.target?.value) {
  //     dispatch(clearConvetedDepositCurrency());
  //   }
  //   if (e?.target?.value && !/^[0-9]*$/.test(e?.target?.value)) {
  //     toast.error("Enter valid amount", { id: "depsoi" });
  //     return;
  //   }
  //   if (e?.target?.value <= 1000) {
  //     setAmount(e?.target?.value);
  //   } else {
  //     toast.error("Cannot Deposit more than 1000", { id: "deposit" });
  //     return;
  //   }

  //   if (selectedCurrency === "BTC") {
  //     if (timeoutValue) clearTimeout(timeoutValue);
  //     timeoutValue = setTimeout(() => {
  //       dispatch(
  //         getConvertedDepositBalanceStart({
  //           currencyCode: "usd",
  //           amount: e?.target?.value,
  //         })
  //       );
  //     }, 500);
  //   }
  // };

  //change to BTC form
  // const handleGenerateAddress = () => {
  //   const validation = [];
  //   if (!selectedCurrency) {
  //     validation.push("Please select currency");
  //   }
  //   if (!amount) {
  //     validation.push("Wallet amount required");
  //   }
  //   if (amount && !/^[0-9]*$/.test(amount)) {
  //     validation.push("Enter a valid amount");
  //   }
  //   if (validation?.length !== 0) {
  //     validation.forEach((message) => {
  //       toast.error(message);
  //     });
  //     return;
  //   }
  //   dispatch(
  //     generateAddressStart({
  //       currencyCode: convertedDepositBalance?.currency_from,
  //       amount,
  //       userName: userDetails?.user?.user_name,
  //     })
  //   );
  //   setFormState("btc");
  // };
  const checkKycDetails = async () => {
    let response;
    try {
      setLoader(true);
      const data = await paymentService.checkUserKYCForPayment();
      if (data.status === 200) {
        return { code: 200 };
      } else {
        setLoader(false);
        return { code: 205 };
      }
    } catch (err) {
      toast.error(response?.message, { id: "check-kyc-details-error" });
      setLoader(false);
      return { code: 400 };
    }
  };
  const callLTCAPI = async (amount) => {
    try {
      const data = await paymentService.initiateXCoinTransaction({
        packageId: activeCard.id,
      });
      const {
        data: { xcoinTransactionId },
      } = data;
      if (
        data.status === 200 &&
        data.message === "Success" &&
        xcoinTransactionId
      ) {
        setLoader(false);
        // window.open(
        //   `${config?.xcoinPaymentUrl}/?ref=${xcoinTransactionId}`,
        //   "_blank"
        // );
        window.location.href = `${config?.xcoinPaymentUrl}/?ref=${xcoinTransactionId}`;
      } else {
        setLoader(false);
        toast.error(data.message, { id: "error-card-delete" });
      }
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(
    () => () => {
      setStep(StepsEnum.Amount);
    },
    []
  );

  //change to cash app form
  const handleCardPayment = async () => {
    const validation = [];
    // if (!selectedCurrency) {
    //   validation.push("Please select currency");
    // }
    if (!activeCard) {
      validation.push("Wallet amount required");
    }
    const amount = activeCard?.price;
    if (amount && !/^[0-9]*$/.test(amount)) {
      validation.push("Enter a valid amount");
    }
    if (amount && amount < 5) {
      validation.push("Cannot deposit less then 5");
    }
    if (validation?.length !== 0) {
      validation.forEach((message) => {
        toast.error(message, { id: "amounterro" });
      });
      return;
    }
    // if (selectedCurrency === "CashApp") {
    //   setFormState("cashApp");
    // }
    // if (selectedCurrency === "XCoins") {
    try {
      // setFormState("xcoins");
      if (parseFloat(amount) < 20) {
        toast.error("The minimum Deposit Amount is $20", {
          id: "onething",
        });
        return;
      } else {
        const response = await checkKycDetails();
        if (response.code === 200) {
          await callLTCAPI(`${activeCard?.price}`);
        } else if (response.code === 205) {
          // setFormState("xcoins");
        }
      }
    } catch (err) {
      console.log("error>>>>>", err);
    }
    // }

    if (cashAppPayList?.status === "pending") {
      setShowHeader(true);
    }
  };

  // this function is for the clearing the upload screen shot
  // const clearScreenShot = () => {
  //   if (screenShot) {
  //     var output = document.getElementById("output");
  //     output.src = URL.createObjectURL(screenShot);
  //     setScreenShot(null);
  //   }
  // };

  //   const LoadScreenShot = async (e) => {
  //     if (!e.target.files[0]) return;
  //     setScreenShot(e.target.files[0]);
  //   };

  // this function is for the submit cashApp form

  const handleSubmitChangeStatus = () => {
    handleDepositWithdrawOpen();
  };

  useEffect(() => {
    if (currencyList?.length !== 0) {
      currencyList?.filter((e) => {
        if (e.code === "BTC") {
          // setSelectedCurrency(e.code);
          return true;
        }
      });
    }
  }, [currencyList]);

  //   const handleCoinSelect = (e) => {
  //     setSelectedCurrency(e?.value);
  //   };

  //   const copyToClipBoard = (e, value) => {
  //     navigator.clipboard.writeText(generateAddress);
  //     toast.success("Copied", {
  //       id: "PAYMENT.PAYMENT_COPIED_CLIPBOARD",
  //     });
  //   };
  useEffect(() => {
    dispatch(getCurrencyListStart());
    return () => {
      dispatch(clearDepositData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCashAppTransactionStart());
    dispatch(getDefaultPayDataStart());
  }, [dispatch]);
  const updateBillingAddress = async () => {
    if (activeCard) {
      const response = await checkKycDetails();
      if (response.code === 200) {
        await callLTCAPI(activeCard?.price);
      }
    }
  };
  useEffect(() => {
    if (billingUpdateSuccess) {
      updateBillingAddress();
    }
  }, [billingUpdateSuccess]);
  const handleChange = (event, newValue) => {
    dispatch(clearDepositStates());
    dispatch(clearConvetedDepositCurrency());
    dispatch(clearConvetedWithdrawCurrency());
    setValue(newValue);
  };

  return (
    <section
      className={`buyNowAccoutMainWrap ${classes.accountRight}   ${
        step === StepsEnum.Success ? "payment-completeMainWrap" : ""
      } ${imageChange ? "image-failed-class" : ""}`}
    >
      {!openConfirmationPopup ? (
        <>
          {showHeader && (
            <Grid className={`${classes.closeIcon} tabClose  `}>
              <div
                className={`flexDiv ${
                  step === StepsEnum.Success ? "successPage" : ""
                } }  `}
              >
                <Box className={`buyNowCloseIcon ${classes.closeIconWrap}`}>
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleDepositWithdrawOpen}
                  />
                </Box>

                <Typography className={`WalletHeading ${classes.heading}`}>
                  {HeaderEnums[step]}
                </Typography>
              </div>
            </Grid>
          )}

          <Box
            className={`${classes.tabWrap} tabWrapContent buyTabWrap tabPadd`}
          >
            {ShowTabsEnum.includes(step) && (
              <Box className="tabList">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="tabs icon"
                  className={`tabHeadingNew ${classes.tabHeading}`}
                >
                  <Tab
                    disableRipple
                    // icon={
                    //   <img
                    //     src={
                    //       value === 1
                    //         ? "/images/deposit-icon.svg"
                    //         : "/images/deposit-icon.svg"
                    //     }
                    //     alt="depo"
                    //   />
                    // }
                    iconPosition={"start"}
                    label="Buy coins"
                    {...a11yProps(1)}
                  />
                  <Tab
                    disableRipple
                    // icon={
                    //   <img
                    //     src={
                    //       value === 2
                    //         ? "/images/payment-icon/withdraw.svg"
                    //         : "/images/payment-icon/withdraw.svg"
                    //     }
                    //     alt="depo"
                    //   />
                    // }
                    iconPosition={"start"}
                    label="Redeem"
                    {...a11yProps(2)}
                    disabled={
                      showStatus === "confirm" || showStatus === "reject"
                    }
                  />
                </Tabs>
              </Box>
            )}
            <TabPanel
              value={value}
              index={0}
              className={`position-relative allGameList ${classes.depositSection} languageButton depositGrid payments-scroll`}
            >
              <Grid className={` buynowCardWrap ${classes.formGroup}`}>
                <SelectedStateView
                  step={step}
                  isPending={isPending}
                  loader={loader}
                  coinPackages={coinPackages}
                  activeCard={activeCard}
                  setActiveCard={setActiveCard}
                  setStep={setStep}
                  handleCardPayment={handleCardPayment}
                />
              </Grid>
            </TabPanel>

            <TabPanel
              value={value}
              index={1}
              className={`position-relative allGameList ${classes.depositSection} languageButton`}
            >
              <Withdraw
                coinList={currencyList}
                handleDepositWithdrawOpen={handleDepositWithdrawOpen}
                setShowHeader={setShowHeader}
                showHeader={showHeader}
                setOpenConfirmationPopup={setOpenConfirmationPopup}
                setPaymentType={setPaymentType}
              />
            </TabPanel>
          </Box>
        </>
      ) : (
        <>
          {(withdrawStatus === "start" || withdrawStatus === "success") && (
            <Grid item className={`${classes.paySuccess} withdrawPopup`}>
              <Grid className={classes.closeIcon}>
                <Box className={classes.closeIconWrapStaus1}>
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleSubmitChangeStatus}
                  />
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.successLogo} ${classes.popup_mt}`}
              >
                <img
                  src={"/images/success.svg"}
                  alt="BTC"
                  style={{ width: "100px" }}
                />
              </Grid>
              <Grid item className={classes.successContent}>
                <h3 style={{ marginTop: "5px" }}>Submitted successfully</h3>

                <p>
                  {`Your ${
                    paymentType === "BTC" ? "Bitcoin" : paymentType
                  } Withdraw Request has been submitted to review successfully. We will notify you when it's completed. Thank you!`}
                </p>
              </Grid>
              <Grid item className={`${classes.successLogo} cashAppBtn`}>
                <Button
                  onClick={handleSubmitChangeStatus}
                  className={`${classes.statusButton} successButton returnButton`}
                  display={"flex"}
                >
                  Return to Home
                </Button>
              </Grid>
            </Grid>
          )}
          {withdrawStatus === "fail" && (
            <Grid item className={`${classes.paySuccess} withdrawPopup`}>
              <Grid className={classes.closeIcon}>
                <Box className={classes.closeIconWrapStaus1}>
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleSubmitChangeStatus}
                  />
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.successLogo} ${classes.popup_mt}`}
              >
                <img
                  src={"/images/failed.svg"}
                  alt="BTC"
                  style={{ width: "100px" }}
                />
              </Grid>
              <Grid item className={classes.successContent}>
                <h3 style={{ marginTop: "5px" }}>Payment Failed</h3>

                <p>
                  {`Sorry! We could not process your ${
                    paymentType === "BTC" ? "Bitcoin" : paymentType
                  } Withdraw Request, please contact “Live Support” for more information.`}
                </p>
              </Grid>
              <Grid item className={`${classes.successLogo} cashAppBtn`}>
                <Button
                  onClick={handleSubmitChangeStatus}
                  className={`${classes.statusButton} successButton returnButton`}
                  display={"flex"}
                >
                  Return to Home
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </section>
  );
};

export default BuyCoins;
