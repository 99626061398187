const StepsEnum = Object.freeze({
  Amount: "amount",
  Method: "method",
  BitCoin: "bitcoin",
  CashApp: "cashapp",
  PayMobil: "paymobil",
  Card: "card",
  Success: "success",
});

const HeaderEnums = Object.freeze({
  [StepsEnum.Success]: "Payment Success",
  [StepsEnum.CashApp]: "Cash App Payment",
  [StepsEnum.Amount]: "Wallet",
  [StepsEnum.Method]: "Wallet",
  [StepsEnum.BitCoin]: "Bitcoin Payment",
});

const ShowTabsEnum = [StepsEnum.Amount, StepsEnum.Method];

export { StepsEnum, HeaderEnums, ShowTabsEnum };
