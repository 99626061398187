import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";

// import { cashAppTransactionStart } from "../../../../redux-saga/redux/CashApp";
import { StepsEnum } from "./constants";
import CircularProgressBar from "./circularProgress";
import cashAppTransactionServices from "../../../../services/cashApp";
import { GlobalLoader } from "../../../../utils/globalLoader";

function isValidURL(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

function formatFileSize(sizeInBytes) {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes}byte`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(2)}kb`;
  } else {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)}mb`;
  }
}
export default function CashAppPayment({
  activeCard,
  setStep,
  setTransactionStatus,
}) {
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  // const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.myProfile);
  const [disableButton, setDisableButton] = useState(false);
  const [screenShot, setScreenShot] = useState(null);
  const [webReceipt, setWebReceipt] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxFileSize = 20 * 1024 * 1024;
    if (file.size > maxFileSize) {
      toast.error("Filesize should be less than 20mb.", {
        id: "fileupload erro",
      });
      return;
    }
    setScreenShot(file);
  };
  console.log(isValidURL(webReceipt));
  const handlePaymentSuccessClick = async () => {
    setLoading(true);
    if (!screenShot && !webReceipt) {
      toast.error("Either Screenshot or Web Receipt is required.", {
        id: "onething",
      });
      setLoading(false);
      return;
    }
    if (!screenShot && !isValidURL(webReceipt)) {
      toast.error("WebReceipt is not valid", {
        id: "webreceipt validation",
      });
      setLoading(false);
      return;
    }
    setDisableButton(true);
    const cashAppRequest = new FormData();
    cashAppRequest.append("selectedCurrency", "CashApp");
    cashAppRequest.append("screenShot", screenShot);
    cashAppRequest.append("webReceipt", webReceipt);
    cashAppRequest.append("userName", userDetails?.user?.user_name);
    cashAppRequest.append("packageId", activeCard?.id);
    // dispatch(cashAppTransactionStart(cashAppRequest));
    try {
      const {
        error,
        data: { data, status },
      } = await cashAppTransactionServices.cashAppTransaction(cashAppRequest);
      setTransactionStatus({
        id: data.id,
        createdAt: data.createdAt,
        type: data.payment,
        payment: data.payment,
        goldCoins: data.goldCoins,
        bonusCoins: data.bonusCoins,
        amount: data.amount,
        address: data.receiveAddress,
        status: status,
      });
      if (!error) {
        toast.success("CashApp Transaction Successfull", {
          id: "CASHAPP.SUCCESSFULL",
        });
      }
      if (error) {
        toast.error("CashApp Transaction Failed", {
          id: "CASHAPP.FAILURE",
        });
      }
    } catch (error) {
      toast.error("CashApp Transaction Failed", {
        id: "CASHAPP.FAILURE",
      });
    }
    setStep(StepsEnum.Success);
    setDisableButton(false);
    setLoading(false);
  };

  const copyHandler = (copyText) => {
    navigator.clipboard.writeText(copyText);
    toast.success("Copied", {
      id: "PAYMENT.PAYMENT_COPIED_CLIPBOARD",
    });
  };
  useEffect(() => {
    if (screenShot) {
      setFileUploaded(true);
      setTimeout(() => {
        setFileUploaded(false);
      }, 1000);
    }
  }, [screenShot]);
  return (
    <Grid className="QRpaymentDetailsWrap">
      <span>Please send ${activeCard?.price} to this Cashtag:</span>
      <div className="paymentMainContentWrap">
        <div className="copyWrap">
          <p>https://cash.app/$dianafta</p>
          <span
            role="button"
            onClick={() => copyHandler("https://cash.app/$dianafta")}
            aria-label="Copy"
            className="mobileCopyImg"
            tabIndex="0"
            onKeyUp={() => {}}
          >
            <img src="/images/copyIcon.png" alt="" />
          </span>
        </div>
        <div className="divider">
          <span>OR</span>
        </div>
        <div className="QRWrap">
          <p>Scan this QR-code:</p>
          <img src="/images/QRCode-Image.png" alt="" />
        </div>
        <div className="secondCopyInput">
          <span>Enter this code in the “Note”</span>
          <div className="copyWrap copyWrapSec">
            <p>5KXB3</p>
            <span
              role="button"
              onClick={() => copyHandler("5KXB3")}
              aria-label="Copy"
              className="mobileCopyImg"
              tabIndex="0"
              onKeyUp={() => {}}
            >
              <img src="/images/copyIcon.png" alt="" />
            </span>
          </div>
        </div>
        <div className="secondCopyInput">
          <span>Web receipt</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Input link to receipt"
              value={webReceipt}
              onChange={(e) => setWebReceipt(e.target.value)}
            />
          </div>
        </div>
        <div className="divider">
          <span>OR</span>
        </div>
        <div className="secondCopyInput">
          <span>Screenshot of the transaction</span>
          <div
            className={`upload-container ${
              screenShot ? "uplodedFile" : "file-uploaded"
            }`}
          >
            <input
              type="file"
              id="fileInput"
              className="file-input"
              accept=".jpg, .png, .pdf"
              onChange={handleFileChange}
            />
            <label htmlFor="fileInput" className="upload-label">
              {screenShot ? (
                <>
                  <span className="cash-app-uploaded-file-name">
                    <p>{screenShot.name}</p>
                  </span>
                  <span
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => {}}
                    className="close-icon progress-bar"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setScreenShot(null);
                    }}
                  >
                    {fileUploaded ? (
                      <CircularProgressBar progress={100} />
                    ) : screenShot ? (
                      <img src="/images/crossIcon.png" alt="" />
                    ) : null}
                  </span>
                </>
              ) : (
                "Upload your file here"
              )}
              <span className="file-types">
                {screenShot
                  ? `${formatFileSize(screenShot.size)}`
                  : "jpg, png, pdf. Max size 20 mb"}
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="flexBtnDiv">
        <div className="select-payment-method-button" style={{ width: "100%" }}>
          <button disabled={loading} onClick={() => setStep(StepsEnum.Method)}>
            Back
          </button>
        </div>
        <div style={{ width: "100%" }}>
          <div className="buyNowBtnWrap">
            <button
              disabled={disableButton}
              onClick={handlePaymentSuccessClick}
              style={{ fontWeight: "500" }}
            >
              {/* <span style={{ fontWeight: "500px" }}>
              Pay</span>$
              {activeCard?.price} */}
              {loading ? (
                <div className="select-payment-method-button">
                  <GlobalLoader />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </Grid>
  );
}
