import React from "react";

import { Grid } from "@mui/material";

import { GlobalLoader } from "../../../../utils/globalLoader";
import { StepsEnum } from "./constants";

export default function SelectPaymentAmount({
  setActiveCard,
  activeCard,
  setStep,
  coinPackages,
  loader,
  isPending,
}) {
  return (
    <>
      <span className="mobileHide">Coin packages</span>
      <div className="paddingBottom50">
        <Grid>
          <div className="card-slider " style={{ padding: "1px" }}>
            {/* Card Container */}
            <div
              className="mobileGap"
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: "6px",
                flexWrap: "wrap",
              }}
            >
              {loader || isPending ? (
                <GlobalLoader />
              ) : (
                coinPackages?.map((card, index) => (
                  <div
                    key={card?.id}
                    className={`card-container ${
                      activeCard?.id === card?.id ? "active" : ""
                    }`}
                    onClick={() => setActiveCard(card)}
                    onDoubleClick={() => setActiveCard(null)}
                    role="presentation"
                  >
                    <div className="card-content">
                      <div className="card-header">
                        <img
                          src={
                            Number(card?.bonus) !== 0
                              ? "/images/Coins.png"
                              : "/images/simpleGold.svg"
                          }
                          alt="Coins"
                          className="icon"
                        />
                      </div>
                      <div className="card-body">
                        <h3>{card.coins} GC</h3>
                        <p>+{card.bonus} free Bonus coins</p>
                      </div>
                      <div className="card-footer">
                        <button className="price-button">
                          {card.isDiscounted && card.mrp !== card.price && (
                            <span className="old-price">${card.mrp}</span>
                          )}
                          ${card.price}
                        </button>
                      </div>
                    </div>
                    {card.isDiscounted && !!Number(card.discount) && (
                      <span className="discountTag">-{card?.discount}%</span>
                    )}
                  </div>
                ))
              )}
            </div>
            {activeCard && (
              <div className="buyNowBtnWrap cardWrapScreen">
                <button onClick={() => setStep(StepsEnum.Method)}>
                  <span>Pay</span> ${activeCard?.price}
                </button>
              </div>
            )}
          </div>
        </Grid>
      </div>
    </>
  );
}
