import React, { useState } from "react";

import { StepsEnum } from "./constants";
import SelectPaymentAmount from "./selectPaymentAmount";
import SelectPaymentMethod from "./selectPaymentMethod";
import CashAppPayment from "./cashAppPayment";
import PaymentSuccess from "./paymentSuccess";
import BitCoinPayment from "./bitCoinPayment";

export default function SelectedStateView({
  step,
  activeCard,
  setActiveCard,
  setStep,
  coinPackages,
  loader,
  handleCardPayment,
  isPending,
}) {
  const [transactionStatus, setTransactionStatus] = useState(null);
  switch (step) {
    case StepsEnum.Amount:
      return (
        <SelectPaymentAmount
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          setStep={setStep}
          coinPackages={coinPackages}
          loader={loader}
          isPending={isPending}
        />
      );
    case StepsEnum.Method:
      return (
        <SelectPaymentMethod
          setStep={setStep}
          loader={loader}
          handleCardPayment={handleCardPayment}
          activeCard={activeCard}
        />
      );
    case StepsEnum.BitCoin:
      return <BitCoinPayment setStep={setStep} activeCard={activeCard} />;

    case StepsEnum.CashApp:
      return (
        <CashAppPayment
          activeCard={activeCard}
          setStep={setStep}
          setTransactionStatus={setTransactionStatus}
        />
      );
    case StepsEnum.PayMobil:
      return <>PayMobil</>;
    case StepsEnum.Card:
      return <>Card</>;
    case StepsEnum.Success:
      return <PaymentSuccess transactionStatus={transactionStatus} />;
    default:
      return (
        <SelectPaymentAmount
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          setStep={setStep}
        />
      );
  }
}
